export const NAV_BAR = {
    main: [
        {
            pageName: 'MP',
            offset: 0
        },
        {
            pageName: 'MP',
            offset: 0
        },
        {
            pageName: 'MP',
            offset: 0
        },
        {
            pageName: 'MP',
            offset: 0
        },
    ],
    promoute: [
        {
            pageName: 'PromP',
            offset: 0
        },
        {
            pageName: 'PromP',
            offset: 0
        },
        {
            pageName: 'PromP',
            offset: 0
        },
    ],
    environment: [
        {
            pageName: 'EP',
            offset: 0
        },
        {
            pageName: 'EP',
            offset: 0
        },
        {
            pageName: 'EP',
            offset: 0
        },
    ],
    product: [
        {
            pageName: 'PrP',
            offset: 0
        },
        {
            pageName: 'PrP',
            offset: 0
        },
        {
            pageName: 'PrP',
            offset: 0
        },
    ]
}
