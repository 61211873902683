import * as React from 'react';
import { ListItem } from 'components/list-item';


export const PROMOTION_LIST_ARRAY_1 = [
	<ListItem 
		title={'Упаковка'} 
		key={'Упаковка'} 
		description={'Упаковка - создаём такое впечатление о продукте, что его хочется купить.'} 
	/>, 
	<ListItem 
		title={'Брендинг'} 
		key={'Брендинг'} 
		description={'Брендинг - создаём уникальный запоминающийся и узнаваемый бренд.'} 
	/>, 
	<ListItem 
		title={'Креативная концепция продвижения'} 
		key={'Креативная концепция продвижения'} 
		description={'Креатив - создаём уникальные креативные концепции и визуализацию.'} 
	/>, 
	<ListItem 
		title={'УТП'} 
		key={'УТП'} 
		description={'УТП - создаём уникальное торговое предложение, дифференцированное от конкурентов.'} 
	/>, 
	<ListItem 
		title={'Ценообразование'} 
		key={'Ценообразование'} 
		description={'Ценообразование - экспертно управляем ценнобразованием относительно ценности продукта для рынка.'} 
	/>
]
export const PROMOTION_LIST_ARRAY_2 = [
	<ListItem 
		title={'Интернет-маркетинг'} 
		key={'Интернет-маркетинг'} 
		description={'Интернет-маркетинг - имеем 15-летний опыт комплесного интернет-маркетинга.'} 
	/>, 
	<ListItem 
		title={'Медиапланирование'} 
		key={'Медиапланирование'} 
		description={'Медиапланирование - детализированно планируем расходы и результаты продвижения на 1-2 года вперёд.'}
	/>, 
	<ListItem 
		title={'Omni-channel marketing'} 
		key={'Omni-channel marketing'} 
		description={'Омниканальный маркетинг - взаимодействуем с целевой аудиторией '
			+ 'продукта в различных каналах в наиболее эффективном порядке.'} 
	/>
]
export const PROMOTION_LIST_ARRAY_3 = [
	<ListItem 
		title={'Продажи'} 
		key={'Продажи'} 
		description={'Продажи - строим отдел экспертных продаж под ключ.'} 
	/>, 
	<ListItem 
		title={'B2C'} 
		key={'B2C'} 
		description={'Розница - создаём поток B2C клиентов.'} 
	/>, 
	<ListItem 
		title={'B2G'} 
		key={'B2G'} 
		description={'Взаимодействие и сотрудничество с государственными структурами'} 
	/>, 
	<ListItem 
		title={'B2B'} 
		key={'B2B'}  
		description={'Продажи бизнесу - настраиваем воронку B2B продаж.'} 
	/>
]
