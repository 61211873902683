import * as React from "react";
import { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import Vademecum from "./icons/vademecum.svg";
import Cossa from "./icons/cossa.svg";
import Rusbase from "./icons/rusbase.svg";
import VademecumMob from "./icons/vademecumMob.svg";
import CossaMob from "./icons/cossaMob.svg";
import RusbaseMob from "./icons/rusbaseMob.svg";
import "./massmedia.scss";

export const Massmedia = (): ReactElement => {
  return (
    <div
      className={
        isMobile ? "massmedia-links-pos-mobile" : "massmedia-links-pos"
      }
    >
      <a
        className="massmedia-link"
        href="https://vademec.ru/news/2020/10/05/v-rossii-zapushchen-venchurnyy-fond-medcraft-fund-dlya-startapov-v-sfere-digital-pharma-healthcare/"
        target='_blank'
      >
        {isMobile ? <VademecumMob /> : <Vademecum />}
      </a>
      <a className="massmedia-link" href="https://www.cossa.ru/news/275537/" target='_blank'>
        {isMobile ? <CossaMob /> : <Cossa />}
      </a>
      <a className="massmedia-link" href="https://rb.ru/news/medcraft-fund/" target='_blank'>
        {isMobile ? <RusbaseMob /> : <Rusbase />}
      </a>
    </div>
  );
};
