import * as React from 'react' 
import { ListItem } from '../../components/list-item';

export const PRODUCT_LIST_ARRAY_1 = [
	<ListItem 
		title={'Бизнес-идея'} 
		key={'Бизнес-идея'} 
		description={'Бизнес идея - помогаем сформировать окончательное решение для рынка, проверяем '
			+ 'жизнеспособность бизес-модели на потребительском рынке.'} 
	/>, 
	<ListItem 
		title={'Исследование - контент анализ'} 
		key={'Исследование - контент анализ'} 
		description={'Исследования - проводим первичные и вторичные исследования рынка, западной практики.'} 
	/>, 
	<ListItem 
		title={'Конкуренты'} 
		key={'Конкуренты'} 
		description={'Конкуренты - проводим глубинный конкурентный анализ, а также анализ продуктов-заменителей.'} 
	/>, 
	<ListItem 
		title={'Сегментирование целевой аудитории'} 
		key={'Сегментирование целевой аудитории'} 
		description={'Целевая аудитория - строим ментальные карты портретов целевой аудитории, '
			+ 'её профили, паттерны, характеристики.'} 
	/>,
	<ListItem 
		title={'Прогнозирование'} 
		key={'Прогнозирование'}  
		description={'Прогнозирование - строим прогнозы по достижимому объёму рынка, экономики бизнес-модели.'} 
	/>
]
export const PRODUCT_LIST_ARRAY_2 = [
	<ListItem 
		title={'Продукт-менеджмент'} 
		key={'Продукт-менеджмент'}  
		description={'Продукт-менеджмент - строим дорожную карту создания продукта и его развития, '
			+ 'помогаем с процессом управления продуктом.'} 
	/>,
	<ListItem 
		title={'Пользовательский опыт '} 
		key={'Пользовательский опыт '} 
		description={'Пользовательский опыт - тестируем опыт пользователя по выбору и использованию продукта.'} 
	/>, 
	<ListItem 
		title={'Карта путешествия клиента'} 
		key={'Карта путешествия клиента'} 
		description={'Карта путешествия клиента - прогнозируем все возможные пути клиента по взаимодействию с продуктом.'} 
	/>, 
	<ListItem 
		title={'Методология AGILE'} 
		key={'Методология AGILE'} 
		description={'Методология AGILE - используем гибкую методологию разработки AGILE.'} 
	/>
]
export const PRODUCT_LIST_ARRAY_3 = [
	<ListItem 
		title={'Разработка'} 
		key={'Разработка'}  
		description={'Разработка - предоставляем команду разработчиков под веб, мобильные приложения и сервисы.'} 
	/>,
	<ListItem 
		title={'Программирование'} 
		key={'Программирование'} 
		description={'Программирование - имеем собственные ресурсы для программирования практически любых технологий.'} 
	/>, 
	<ListItem 
		title={'Технологии'} 
		key={'Технологии'} 
		description={'Технологии - применяем накопленный стек технологий и решений для максимизации '
			+ 'скорости разработки, используя самые передовые технологии.'} 
		/>, 
	<ListItem 
		title={'Дизайн'} 
		key={'Дизайн'} 
		description={'Дизайн - создаём визуальное решение на основе пользовательского опыта.'} 
	/>, 
	<ListItem 
		title={'Архитектура'} 
		key={'Архитектура'}  
		description={'Архитектура - создаём гибкую архитектуру продукта.'} 
	/>
]
